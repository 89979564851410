import clsx from "clsx";
import { Button, Checkbox, InputNumber, Space, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { CloseCircleOutlined } from "@ant-design/icons";
export const SalesFilter = ({ store }) => {
  const { filter_by, setFilter } = store;

  const [localFilterBy, setLocalFilterBy] = useState({
    without_sold: false,
    without_updates: false,
    day_period: 0,
  });

  const onChecked = ({ target }) => {
    setLocalFilterBy({ ...localFilterBy, [target.name]: target.checked });
  };

  useEffect(() => {
    setLocalFilterBy({
      without_sold: filter_by.without_sold || false,
      without_updates: filter_by.without_updates || false,
      day_period: filter_by.day_period || 0,
    });
  }, [filter_by]);

  const onApply = () => {
    setFilter({
      without_sold:
        localFilterBy.without_sold && localFilterBy.day_period ? true : null,
      without_updates:
        localFilterBy.without_updates && localFilterBy.day_period ? true : null,
      day_period:
        ((localFilterBy.without_sold || localFilterBy.without_updates) &&
          localFilterBy.day_period) ||
        null,
    });
  };

  const clearFilter = () => {
    setFilter({
      without_sold: null,
      without_updates: null,
      day_period: null,
    });
  };

  const isDiff =
    localFilterBy.without_sold !== Boolean(filter_by.without_sold) ||
    localFilterBy.without_updates !== Boolean(filter_by.without_updates) ||
    Boolean(localFilterBy.day_period) !== Boolean(filter_by.day_period) ||
    (Boolean(localFilterBy.day_period) &&
      localFilterBy.day_period !== filter_by.day_period);

  const isNoSense =
    (!filter_by.without_sold &&
      !filter_by.without_updates &&
      !localFilterBy.day_period) ||
    (Boolean(localFilterBy.day_period) &&
      !localFilterBy.without_sold &&
      !localFilterBy.without_updates &&
      !filter_by.without_sold &&
      !filter_by.without_updates);

  const isAppliedValue = filter_by.without_sold || filter_by.without_updates;

  if (!store) return null;

  return (
    <div className="flex justify-between">
      <div className="flex flex-col gap-1">
        <Checkbox
          checked={localFilterBy.without_sold}
          name="without_sold"
          onChange={onChecked}
        >
          Unsold
        </Checkbox>

        <Checkbox
          name="without_updates"
          checked={localFilterBy.without_updates}
          onChange={onChecked}
        >
          No Price Update
        </Checkbox>
      </div>

      <div className="flex flex-col text-slate-500 w-1/2">
        <span className="text-xs ml-2 -mt-1 mb-[4px] flex items-center">
          {filter_by.without_sold && <FilterRealValueBadge tooltip="Unsold" />}
          {filter_by.without_updates && (
            <FilterRealValueBadge tooltip="No Price Update" />
          )}

          <span className={clsx(isAppliedValue && "text-red-600")}>
            For <strong>{isAppliedValue && filter_by.day_period}</strong> last
            days
          </span>

          {isAppliedValue && (
            <CloseCircleOutlined
              className="flex ml-2 text-red-300 text-sm cursor-pointer hover:bg-red-200 hover:text-red-600 rounded-full"
              onClick={clearFilter}
            />
          )}
        </span>

        <Space.Compact className="w-full">
          <InputNumber
            className="w-full"
            min={0}
            value={localFilterBy.day_period}
            onChange={value => {
              setLocalFilterBy({
                ...localFilterBy,
                day_period: value,
              });
            }}
          />

          <Button
            disabled={!isDiff || isNoSense}
            className="text-xs"
            type="primary"
            onClick={onApply}
          >
            Apply
          </Button>
        </Space.Compact>
      </div>
    </div>
  );
};

const FilterRealValueBadge = ({ text, tooltip = "", className }) => {
  return (
    <Tooltip title={tooltip} className={clsx(tooltip && "cursor-pointer")}>
      <strong
        className={clsx(
          "text-white mr-1 bg-red-500 rounded px-1 leading-tight",
          className,
        )}
        style={{ fontSize: 10 }}
      >
        {tooltip[0]?.toUpperCase() || text}
      </strong>
    </Tooltip>
  );
};
