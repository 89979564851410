import { useState } from "react";
import _ from "lodash";
import { Table } from "antd";
import { Pagination } from "components";
import { useRepairPartsStore, useRepairParts } from "features/directory";

export const RepairPartsTable = ({ onEdit }) => {
  const store = useRepairPartsStore();
  const { repairParts, isLoading, isFetching } = useRepairParts();

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const onExpand = id => {
    if (_.isEqual(expandedRowKeys, [id])) {
      setExpandedRowKeys([]);
    } else {
      setExpandedRowKeys([id]);
    }
  };

  return (
    <>
      <Table
        className="overflow-y-auto"
        rowClassName="hover:cursor-pointer"
        loading={isLoading || isFetching}
        columns={columns}
        dataSource={repairParts?.data || []}
        sticky={true}
        rowKey={record => record.name}
        pagination={false}
        expandable={{
          expandedRowClassName: () => "bg-slate-100",
          expandedRowKeys: expandedRowKeys,
          expandedRowRender: ({ repair_parts }) => {
            return (
              <Table
                className="my-4 -ml-6"
                rowClassName="hover:cursor-pointer"
                columns={innerColumns}
                rowKey={record => record.id}
                dataSource={repair_parts}
                pagination={false}
                size="small"
                bordered
                onRow={record => ({
                  onClick: () => onEdit(record),
                })}
              />
            );
          },
          onExpand: (expanded, record) => onExpand(record.id),
        }}
        onRow={record => ({
          onClick: () => onExpand(record.name),
        })}
      />

      <Pagination
        store={store}
        disabled={isLoading || !repairParts}
        className="mt-auto"
      />
    </>
  );
};

const columns = [
  { title: "SKU", dataIndex: "sku", key: "sku", align: "left", width: 120 },
  { title: "Name", dataIndex: "name", key: "name", align: "center" },
  {
    title: "Categories",
    key: "repair_part_categories",
    dataIndex: "repair_part_categories",
    render: arr => arr.map(item => item.name).join(", "),
    align: "center",
  },
  {
    title: "Total Quantity",
    dataIndex: "total_quantity",
    key: "total_quantity",
    width: 150,
    align: "center",
  },
];

const InnerTableText = ({ title, text }) => (
  <span className={`${title ? "font-bold" : ""} text-slate-500`}>{text}</span>
);
const innerColumns = [
  {
    title: <InnerTableText title text="ID" />,
    render: text => <InnerTableText text={text} />,
    dataIndex: "id",
    key: "id",
    align: "center",
    width: 120,
  },
  {
    title: <InnerTableText title text="Description" />,
    render: text => <InnerTableText text={text} />,
    dataIndex: "description",
    key: "description",
    align: "center",
    width: "44%",
  },
  {
    title: <InnerTableText title text="Quantity" />,
    render: text => <InnerTableText text={text} />,
    dataIndex: "quantity",
    key: "quantity",
    align: "center",
  },
  {
    title: <InnerTableText title text="Price" />,
    render: price => <InnerTableText text={`€ ${price}`} />,
    dataIndex: "price",
    key: "price",
    align: "center",
  },
];
