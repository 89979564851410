import { PaginatedSelect } from "components";
import { Form } from "antd";
import {
  useInfiniteAccessoryCategories,
  useInfiniteRepairPartsCategories,
} from "features/directory";
import { requiredRule } from "constants/validation";

const modes = ["accessory", "repairParts"];

export const CategoriesInput = ({
  className = "",
  disabled,
  onClear,
  onChange,
  initialData,
  placeholder = "Select Category",
  name = "accessory_categories",
  label = "Categories",
  rules = [requiredRule],
  mode = "accessory",
  isFormItem = true,
}) => {
  if (!modes.includes(mode)) {
    throw new Error("Invalid mode");
  }

  const hooks = {
    accessory: useInfiniteAccessoryCategories,
    repairParts: useInfiniteRepairPartsCategories,
  };

  const {
    categoriesOptions,
    isFetchingNextPage,
    isLoading,
    fetchNextPage,
    setSearchQuery,
    searchQuery,
  } = hooks[mode]();

  const noInitialOption =
    initialData &&
    !categoriesOptions?.some(({ value }) => value === initialData.id);

  if (isFormItem)
    return (
      <Form.Item
        className={className}
        required
        name={name}
        label={label}
        labelCol={{ span: 5 }}
        rules={rules}
      >
        <PaginatedSelect
          disabled={disabled}
          searchValue={searchQuery}
          isLoading={isLoading}
          placeholder={placeholder}
          fetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
          onSearch={setSearchQuery}
          onClear={onClear}
          onChange={id => onChange?.(id)}
          options={
            noInitialOption
              ? [
                  { value: initialData.id, label: initialData.name },
                  ...categoriesOptions,
                ]
              : categoriesOptions
          }
        />
      </Form.Item>
    );

  return (
    <PaginatedSelect
      className={className}
      disabled={disabled}
      searchValue={searchQuery}
      isLoading={isLoading}
      placeholder={placeholder}
      fetchNextPage={fetchNextPage}
      isFetchingNextPage={isFetchingNextPage}
      onSearch={setSearchQuery}
      onClear={onClear}
      onChange={id => onChange?.(id)}
      options={
        noInitialOption
          ? [
              { value: initialData.id, label: initialData.name },
              ...categoriesOptions,
            ]
          : categoriesOptions
      }
    />
  );
};
