import { create } from "lib/zustand-poxy";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { pagination, search } from "utils/standart-stores";

export const useSalesHistoryPagination = create(set => ({
  page: 1,
  pageSize: 12,
  total: 0,
  setPageSize: pageSize => set({ pageSize }),
  setPage: page => set({ page }),
  setTotal: total => set({ total }),
}));

export const useSkuConnectedProductsStore = create(set => ({
  page: 1,
  pageSize: 12,
  total: 0,
  setPageSize: pageSize => set({ pageSize }),
  setPage: page => set({ page }),
  setTotal: total => set({ total }),
}));

export const useSkuLatestConnectedProductsStore = create(set => ({
  page: 1,
  pageSize: 12,
  total: 0,
  setPageSize: pageSize => set({ pageSize }),
  setPage: page => set({ page }),
  setTotal: total => set({ total }),
}));

export const useSkuReturnedProductsStore = create(set => ({
  page: 1,
  pageSize: 12,
  total: 0,
  setPageSize: pageSize => set({ pageSize }),
  setPage: page => set({ page }),
  setTotal: total => set({ total }),
}));

export const useSkuDisconnectedProductsStore = create(set => ({
  page: 1,
  pageSize: 12,
  total: 0,
  setPageSize: pageSize => set({ pageSize }),
  setPage: page => set({ page }),
  setTotal: total => set({ total }),
}));

export const useOnSaleSkuStore = create(
  devtools(
    immer(set => ({
      sorting: {
        sort_by_market_return: null,
        sort_by_ready_for_sale: null,
        sort_by_entered: null,
        sort_by_identified: null,
        sort_by_graded: null,
        sort_by_registered: null,
        sort_by_hold: null,
        sort_by_repair: null,
        sort_by_auction: null,
        sort_by_on_sale: null,
        sort_by_sku: null,
      },
      filter_by: {},
      searchQuery: "",
      page: 1,
      pageSize: 12,
      total: 0,
      setSorting: (key, value) =>
        set(state => {
          for (const sortingKey in state.sorting) {
            if (sortingKey === key) {
              state.sorting[sortingKey] = value;
            } else {
              state.sorting[sortingKey] = null;
            }
          }
        }),
      setSearchQuery: searchQuery =>
        set(state => {
          state.searchQuery = searchQuery;
        }),
      setPageSize: pageSize =>
        set(state => {
          state.pageSize = pageSize;
        }),
      setPage: page => set({ page }),
      setTotal: total => set({ total }),
      setFilter: fieldsObj => {
        set(state => {
          Object.entries(fieldsObj).forEach(([key, value]) => {
            if (value === null || value === undefined) {
              delete state.filter_by[key];
            } else {
              state.filter_by[key] = value;
            }
          });
        });
      },
      clearFilters: () => {
        set(state => {
          state.filter_by = {};
        });
      },
    })),
  ),
);

export const useSKUPairListStore = create(
  devtools(
    immer(set => ({
      ...pagination(set),
      ...search(set),
    })),
  ),
);

export const useSalesIssuesStore = create(
  devtools(
    immer(set => ({
      ...pagination(set),
      ...search(set),
    })),
  ),
);
