import { useEffect } from "react";
import { Button, Form, Input, InputNumber, Modal, message } from "antd";
import { DeleteFilled, ExclamationCircleFilled } from "@ant-design/icons";
import {
  useCreateAccessory,
  useEditAccessory,
  useRemoveAccessory,
} from "features/directory";
import { requiredRule } from "constants/validation";
import { CategoriesInput } from "../CategoriesInput";

const { TextArea } = Input;

export const AccessoryForm = ({ isOpen, initialData, onClose }) => {
  const [form] = Form.useForm();

  const closeAndClear = () => {
    onClose();
    form.resetFields();
  };

  const onSuccess = res => {
    message.success(res.message);
    closeAndClear();
  };

  const { create, isCreating } = useCreateAccessory({ onSuccess });
  const { edit, isEditing } = useEditAccessory({ onSuccess });
  const { remove, isRemoving } = useRemoveAccessory({ onSuccess });

  const onConfirm = value => {
    // TODO: remove when accessory_categories will become multiselect
    const formData = {
      ...value,
      accessory_categories: [value.accessory_categories],
    };

    if (initialData) {
      edit({
        id: initialData.id,
        data: formData,
      });
    } else {
      create(formData);
    }
  };

  const onRemove = () => {
    Modal.confirm({
      title: "Attention!",
      icon: <ExclamationCircleFilled />,
      content: (
        <span>
          Are you sure you want to delete accessory
          <strong className="ml-1">{initialData.name}</strong>?
        </span>
      ),
      okText: "Delete",
      onOk: () => remove(initialData.id),
    });
  };

  useEffect(() => {
    if (initialData) {
      form.setFieldValue("name", initialData.name);
      form.setFieldValue("description", initialData.description);
      form.setFieldValue("sku", initialData.sku);
      form.setFieldValue("quantity", initialData.quantity);
      // TODO: change when accessory_categories will become multiselect
      form.setFieldValue(
        "accessory_categories",
        initialData.accessory_categories[0]?.id,
      );
    }
  }, [isOpen, initialData]);

  const idle = isCreating || isEditing || isRemoving;
  const title = initialData ? "Edit Accessory" : "Create Accessory";

  return (
    <Modal
      title={title}
      open={isOpen}
      onCancel={() => !idle && closeAndClear()}
      destroyOnClose
      footer={
        <div className="flex">
          {initialData && (
            <Button
              danger
              icon={<DeleteFilled />}
              onClick={onRemove}
              disabled={idle}
            >
              Delete
            </Button>
          )}

          <div className="ml-auto">
            <Button disabled={idle} onClick={() => !idle && closeAndClear()}>
              Cancel
            </Button>

            <Button
              type="primary"
              onClick={form.submit}
              loading={isEditing}
              disabled={idle}
            >
              Ok
            </Button>
          </div>
        </div>
      }
    >
      <Form form={form} onFinish={onConfirm} disabled={idle} className={"mt-6"}>
        <Form.Item
          required
          name="name"
          label="Name"
          labelCol={{ span: 5 }}
          rules={[requiredRule]}
        >
          <TextArea autoSize placeholder="Enter accessory name" />
        </Form.Item>

        <Form.Item
          required
          name="sku"
          label="SKU"
          labelCol={{ span: 5 }}
          rules={[requiredRule]}
        >
          <Input placeholder="Enter SKU" />
        </Form.Item>

        <CategoriesInput
          name="accessory_categories"
          disabled={idle}
          onClear={() => form.setFieldValue("accessory_categories", null)}
          initialData={initialData?.accessory_categories?.[0]}
        />

        <Form.Item
          label="Quantity"
          name="quantity"
          labelCol={{ span: 5 }}
          rules={[requiredRule]}
        >
          <InputNumber min={0} />
        </Form.Item>

        <Form.Item
          name="description"
          label="Description"
          labelCol={{ span: 5 }}
        >
          <TextArea
            autoSize
            placeholder="Enter some description if necessary"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
