import { useState } from "react";
import clsx from "clsx";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useAuthStore } from "features/auth";
import {
  CategoryForm,
  CategoryList,
  useRepairPartsCategories,
  useRepairPartsCategoriesStore,
} from "features/directory";
import { SearchInput } from "components";

export const RepairPartsCategories = () => {
  const store = useRepairPartsCategoriesStore();

  const { isRight } = useAuthStore();
  const userCanReadOnly = !isRight(["admin", "registrar", "head_of_flasher"]);

  const { categories, isLoading, isFetching } = useRepairPartsCategories();

  const [formState, setFormState] = useState({
    isOpen: false,
    category: null,
  });
  const open = (category = null) => setFormState({ isOpen: true, category });
  const close = () => setFormState({ isOpen: false, category: null });

  return (
    <div className="page-container flex-col relative">
      <h2 className="text-center mb-6 sm:mb-10">Repair Parts Categories</h2>

      <div className="flex justify-between mb-3 gap-2">
        <SearchInput
          searchQuery={store?.search_term}
          setSearchQuery={store?.setSearchTerm}
        />

        <Button
          type="primary"
          className={clsx("text-xs sm:text-sm", userCanReadOnly && "hidden")}
          onClick={() => open()}
          icon={<PlusOutlined />}
        >
          New Category
        </Button>
      </div>

      <CategoryList
        onEdit={open}
        editable={!userCanReadOnly}
        categories={categories}
        isLoading={isLoading}
        isFetching={isFetching}
      />

      {!userCanReadOnly && (
        <CategoryForm
          isOpen={formState.isOpen}
          onClose={close}
          initialData={formState.category}
          mode="repairParts"
        />
      )}
    </div>
  );
};
