import { create } from "lib/zustand-poxy";
import { immer } from "zustand/middleware/immer";
import { devtools } from "zustand/middleware";
import { v4 as uuidv4 } from "uuid";
import { filters, pagination, search } from "utils/standart-stores";

const createEmptyField = () => ({ name: "", type: "text" });
const createEmptyOption = () => ({ key: uuidv4(), value: "" });

export const useFormConstructor = create(
  devtools(
    immer((set, get) => ({
      fields: [],
      validation: false,
      clear: () => {
        set(state => {
          state.fields = [];
          state.validation = false;
        });
      },
      addNewField: (values = {}) => {
        set(state => {
          state.fields.push({ ...createEmptyField(), ...values });
        });
      },
      onChangeType: (index, type) => {
        set(state => {
          const oldType = state.fields[index].type;
          console.log(oldType);
          const wasSelect = oldType === "select" || oldType === "multiselect";
          state.fields[index].type = type;

          if (type === "select" || type === "multiselect") {
            if (!wasSelect) {
              const emptyOption = createEmptyOption();
              state.fields[index].options = [emptyOption];
              state.fields[index].optionsIndexes = { [emptyOption.key]: 0 };
            }
          } else {
            delete state.fields[index].options;
          }

          state.validation = false;
        });
      },
      onChangeName: (index, name) => {
        set(state => {
          state.fields[index].name = name;
          state.validation = false;
        });
      },
      addNewOption: index => {
        set(state => {
          const emptyOption = createEmptyOption();
          const i = state.fields[index].options.length;
          state.fields[index].options.push(emptyOption);
          state.fields[index].optionsIndexes[emptyOption.key] = i;
        });
      },
      onChangeOptionText: (index, value, key) => {
        set(state => {
          state.validation = false;
          const optIndex = state.fields[index].optionsIndexes[key];
          state.fields[index].options[optIndex].value = value;
        });
      },
      removeOption: (index, key) => {
        set(state => {
          const optIndex = state.fields[index].optionsIndexes[key];
          state.fields[index].options.splice(optIndex, 1);
          for (let i = optIndex; i < state.fields[index].options.length; i++) {
            const current = state.fields[index].options[i];
            state.fields[index].optionsIndexes[current.key] = i;
          }
        });
      },
      removeField: index => {
        set(state => {
          state.fields = state.fields.filter((_, i) => i !== index);
        });
      },
      validate: () => {
        let isValid = true;

        set(state => {
          state.validation = true;

          state.fields.forEach(field => {
            const noName = field.name.toString().trim() === "";
            const isSelect =
              field.type === "select" || field.type === "multiselect";
            const noOptions = !field.options?.some(opt => !!opt.value);

            if (noName || (isSelect && noOptions)) {
              field.error = true;
              isValid = false;
            } else {
              field.error = false;
            }
          });
        });

        return isValid;
      },
      swapFields: index => {
        set(({ fields }) => {
          const buffer = fields[index];
          fields[index] = fields[index + 1];
          fields[index + 1] = buffer;
        });
      },
      setData: data => {
        set(state => {
          state.fields = data.fields.map(field => {
            const result = {
              ...field,
              type: field.type?.toLowerCase(),
              optionsIndexes: {},
            };

            if (!!field.options) {
              result.options = field.options.map((opt, i) => {
                const key = opt.id || opt.key || uuidv4();
                result.optionsIndexes[key] = i;

                return { ...opt, key };
              });
            }

            return result;
          });
        });
      },
    })),
  ),
);

export const useDepartmentsPagination = create((set, get) => ({
  searchQuery: "",
  page: 1,
  pageSize: 12,
  total: 0,
  setSearchQuery: searchQuery => set({ searchQuery }),
  setPageSize: pageSize => set({ pageSize }),
  setPage: page => set({ page }),
  setTotal: total => set({ total }),
}));

export const useSuppliersSelection = create((set, get) => ({
  searchQuery: "",
  page: 1,
  pageSize: 12,
  total: 0,
  setSearchQuery: searchQuery => set({ searchQuery }),
  setPageSize: pageSize => set({ pageSize }),
  setPage: page => set({ page }),
  setTotal: total => set({ total }),
}));

export const useSKUSelection = create((set, get) => ({
  searchQuery: "",
  page: 1,
  pageSize: 12,
  total: 0,
  setSearchQuery: searchQuery => set({ searchQuery }),
  setPageSize: pageSize => set({ pageSize }),
  setPage: page => set({ page }),
  setTotal: total => set({ total }),
}));

export const useDeviceTypeSelection = create((set, get) => ({
  page: 1,
  pageSize: 12,
  total: 0,
  setPageSize: pageSize => set({ pageSize }),
  setPage: page => set({ page }),
  setTotal: total => set({ total }),
}));

export const useColorsSelection = create((set, get) => ({
  page: 1,
  pageSize: 12,
  total: 0,
  setPageSize: pageSize => set({ pageSize }),
  setPage: page => set({ page }),
  setTotal: total => set({ total }),
}));

export const useGradesSelection = create((set, get) => ({
  page: 1,
  pageSize: 12,
  total: 0,
  setPageSize: pageSize => set({ pageSize }),
  setPage: page => set({ page }),
  setTotal: total => set({ total }),
}));

export const useAccessoryCategoriesStore = create(
  devtools(
    immer(set => ({
      ...pagination(set),
      ...search(set),
    })),
  ),
);

export const useAccessoriesStore = create(
  devtools(
    immer(set => ({
      ...pagination(set),
      ...search(set),
      ...filters(set),
    })),
  ),
);

export const useInfAccessoriesStore = create(
  devtools(
    immer(set => ({
      ...search(set),
      ...filters(set),
    })),
  ),
);

export const useRepairPartsCategoriesStore = create(
  devtools(
    immer(set => ({
      ...pagination(set),
      ...search(set),
    })),
  ),
);

export const useRepairPartsStore = create(
  devtools(
    immer(set => ({
      ...pagination(set),
      ...search(set),
      ...filters(set),
    })),
  ),
);

export const useInfRepairPartsStore = create(
  devtools(
    immer(set => ({
      ...search(set),
      ...filters(set),
    })),
  ),
);
