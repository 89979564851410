import { useEffect } from "react";
import { Button, Form, Input, InputNumber, Modal, message } from "antd";
import { DeleteFilled, ExclamationCircleFilled } from "@ant-design/icons";
import {
  useCreateRepairPart,
  useEditRepairPart,
  useRemoveRepairPart,
} from "features/directory";
import { requiredRule } from "constants/validation";
import { CategoriesInput } from "../CategoriesInput";

const { TextArea } = Input;

export const RepairPartForm = ({ isOpen, initialData, onClose }) => {
  const [form] = Form.useForm();

  const closeAndClear = () => {
    onClose();
    form.resetFields();
  };

  const onSuccess = res => {
    message.success(res.message);
    closeAndClear();
  };

  const { create, isCreating } = useCreateRepairPart({ onSuccess });
  const { edit, isEditing } = useEditRepairPart({ onSuccess });
  const { remove, isRemoving } = useRemoveRepairPart({ onSuccess });

  const onConfirm = value => {
    // TODO: remove when repair_part_categories will become multiselect
    const formData = {
      ...value,
      repair_part_categories: [value.repair_part_categories],
    };

    if (initialData) {
      edit({
        id: initialData.id,
        data: formData,
      });
    } else {
      create(formData);
    }
  };

  const onRemove = () => {
    Modal.confirm({
      title: "Attention!",
      icon: <ExclamationCircleFilled />,
      content: (
        <span>
          Are you sure you want to delete repair part
          <strong className="ml-1">#{initialData.id}</strong>?
        </span>
      ),
      okText: "Delete",
      onOk: () => remove(initialData.id),
    });
  };

  useEffect(() => {
    if (initialData) {
      form.setFieldValue("description", initialData.description);
      form.setFieldValue("quantity", initialData.quantity);
      form.setFieldValue("price", initialData.price);
    }
  }, [isOpen, initialData, form]);

  const idle = isCreating || isEditing || isRemoving;
  const title = initialData ? "Edit Repair Part" : "Create Repair Part";

  return (
    <Modal
      title={title}
      open={isOpen}
      onCancel={() => !idle && closeAndClear()}
      destroyOnClose
      footer={
        <div className="flex">
          {initialData && (
            <Button
              icon={<DeleteFilled />}
              onClick={onRemove}
              disabled={idle}
              danger
            >
              Delete
            </Button>
          )}

          <div className="ml-auto">
            <Button disabled={idle} onClick={() => !idle && closeAndClear()}>
              Cancel
            </Button>

            <Button
              type="primary"
              onClick={form.submit}
              loading={isEditing}
              disabled={idle}
            >
              Ok
            </Button>
          </div>
        </div>
      }
    >
      <Form form={form} onFinish={onConfirm} disabled={idle} className={"mt-8"}>
        {!initialData && (
          <>
            <Form.Item
              required
              name="name"
              label="Name"
              labelCol={{ span: 5 }}
              rules={[requiredRule]}
            >
              <TextArea autoSize placeholder="Enter name" />
            </Form.Item>

            <Form.Item
              required
              name="sku"
              label="SKU"
              labelCol={{ span: 5 }}
              rules={[requiredRule]}
            >
              <Input placeholder="Enter SKU" />
            </Form.Item>

            <CategoriesInput
              mode="repairParts"
              name="repair_part_categories"
              disabled={idle}
              onClear={() => form.setFieldValue("repair_part_categories", null)}
              initialData={initialData?.repair_part_categories?.[0]}
            />
          </>
        )}

        <Form.Item
          required
          className="m-0"
          labelCol={{ span: 5 }}
          label="Price"
        >
          <Form.Item
            className="inline-block mr-9"
            name="price"
            rules={[requiredRule]}
          >
            <InputNumber min={0} addonBefore="€" className="w-36" />
          </Form.Item>

          <Form.Item
            className="inline-block"
            label="Quantity"
            name="quantity"
            rules={[requiredRule]}
          >
            <InputNumber min={0} className="w-28" />
          </Form.Item>
        </Form.Item>

        <Form.Item
          name="description"
          label="Description"
          labelCol={{ span: 5 }}
        >
          <TextArea
            autoSize
            placeholder="Enter some description if necessary"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
