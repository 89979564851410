import { useMutation, useQuery, useInfiniteQuery } from "react-query";
import { useMemo, useState } from "react";
import { useDebounce } from "use-debounce";
import { apiClient } from "lib/api-client";
import { queryClient } from "lib/react-query-client";
import {
  useRepairPartsCategoriesStore,
  useRepairPartsStore,
} from "../../store";

// CATEGORIES

const getCategories = params =>
  apiClient.get("api/directory/repair-parts/categories/list", {
    params,
  });

export const useRepairPartsCategories = ({ onSuccess, onError } = {}) => {
  const { page, per_page, search_term, setTotal } =
    useRepairPartsCategoriesStore();

  const [debouncedQuery] = useDebounce(search_term, 500);

  const {
    data: categories,
    isLoading,
    isFetching,
  } = useQuery(
    ["repair-parts-categories", page, per_page, debouncedQuery],
    () =>
      getCategories({
        page,
        per_page,
        search_term: debouncedQuery,
      }),
    {
      onSuccess: ({ meta, data }) => {
        setTotal?.(meta.total, meta.last_page);
        onSuccess?.(data);
      },
      onError: err => {
        onError?.(err);
      },
    },
  );

  return { categories, isLoading, isFetching };
};

const addCategory = data =>
  apiClient.post("api/directory/repair-parts/categories/create", data);

export const useCreateRepairPartsCategory = ({ onSuccess } = {}) => {
  const { mutate: create, isLoading: isCreating } = useMutation(
    data => addCategory(data),
    {
      onSuccess: async res => {
        await queryClient.invalidateQueries(["repair-parts-categories"]);
        onSuccess?.(res);
      },
    },
  );

  return { create, isCreating };
};

const editCategory = (id, data) =>
  apiClient.put(`api/directory/repair-parts/categories/edit/${id}`, data);

export const useEditRepairPartsCategory = ({ onSuccess } = {}) => {
  const { mutate: edit, isLoading: isEditing } = useMutation(
    ({ id, data }) => editCategory(id, data),
    {
      onSuccess: async res => {
        await queryClient.invalidateQueries(["repair-parts-categories"]);
        onSuccess?.(res);
      },
    },
  );

  return { edit, isEditing };
};

const deleteCategory = id =>
  apiClient.delete(`api/directory/repair-parts/categories/${id}`);

export const useRemoveRepairPartsCategory = ({ onSuccess } = {}) => {
  const { mutate: remove, isLoading: isRemoving } = useMutation(
    id => deleteCategory(id),
    {
      onSuccess: async res => {
        await queryClient.invalidateQueries(["repair-parts-categories"]);
        onSuccess?.(res);
      },
    },
  );

  return { remove, isRemoving };
};

const infiniteGetQuery = async ({ pageParam, search_term, filter_by }) => {
  const result = await apiClient.get(pageParam, {
    params: { search_term, filter_by },
  });

  return {
    data: result.data,
    next: result.links.next,
    total: result.meta.total,
  };
};

export const useInfiniteRepairPartsCategories = ({ onSuccess } = {}) => {
  const [searchQuery, setSearchQuery] = useState("");

  const [debouncedQuery] = useDebounce(searchQuery, 500);

  const {
    data: categories,
    isFetchingNextPage,
    fetchNextPage,
    isLoading,
  } = useInfiniteQuery(
    ["i-repair-parts-categories", debouncedQuery],
    ({
      pageParam = `${
        import.meta.env.VITE_APP_API_BASE_URL
      }/api/directory/repair-parts/categories/list?per_page=12&page=1`,
    }) =>
      infiniteGetQuery({
        pageParam,
        search_term: debouncedQuery || null,
      }),
    {
      onSuccess: data => {
        onSuccess?.(data);
      },
      getPreviousPageParam: firstPage => firstPage.next ?? undefined,
      getNextPageParam: lastPage => lastPage.next ?? undefined,
    },
  );

  const categoriesOptions = useMemo(() => {
    const data = categories?.pages?.map(({ data }) => data)?.flat();
    return data?.map(({ id, name }) => ({ value: id, label: name })) || [];
  }, [categories]);

  return {
    categories,
    categoriesOptions,
    searchQuery,
    isFetchingNextPage,
    isLoading,
    fetchNextPage,
    setSearchQuery,
  };
};

// REPAIR PARTS

const getRepairParts = params =>
  apiClient.get("api/directory/repair-parts/list", {
    params,
  });

export const useRepairParts = ({ onSuccess, onError } = {}) => {
  const { page, per_page, search_term, setTotal, filter_by } =
    useRepairPartsStore();

  const [debouncedQuery] = useDebounce(search_term, 500);

  const {
    data: repairParts,
    isLoading,
    isFetching,
  } = useQuery(
    ["repair-parts", page, per_page, debouncedQuery, filter_by],
    () =>
      getRepairParts({
        page,
        per_page,
        search_term: debouncedQuery,
        filter_by,
      }),
    {
      onSuccess: ({ meta, data }) => {
        setTotal?.(meta.total, meta.last_page);
        onSuccess?.(data);
      },
      onError: err => {
        onError?.(err);
      },
    },
  );

  return { repairParts, isLoading, isFetching };
};

const createRepairPart = data =>
  apiClient.post("api/directory/repair-parts/create", data);

export const useCreateRepairPart = ({ onSuccess } = {}) => {
  const { mutate: create, isLoading: isCreating } = useMutation(
    data => createRepairPart(data),
    {
      onSuccess: async res => {
        await queryClient.invalidateQueries(["repair-parts"]);
        onSuccess?.(res);
      },
    },
  );

  return { create, isCreating };
};

const editRepairPart = (id, data) =>
  apiClient.put(`api/directory/repair-parts/edit/${id}`, data);

export const useEditRepairPart = ({ onSuccess } = {}) => {
  const { mutate: edit, isLoading: isEditing } = useMutation(
    ({ id, data }) => editRepairPart(id, data),
    {
      onSuccess: async res => {
        await queryClient.invalidateQueries(["repair-parts"]);
        onSuccess?.(res);
      },
    },
  );

  return { edit, isEditing };
};

const deleteRepairPart = id =>
  apiClient.delete(`api/directory/repair-parts/${id}`);

export const useRemoveRepairPart = ({ onSuccess } = {}) => {
  const { mutate: remove, isLoading: isRemoving } = useMutation(
    id => deleteRepairPart(id),
    {
      onSuccess: async res => {
        await queryClient.invalidateQueries(["repair-parts"]);
        onSuccess?.(res);
      },
    },
  );

  return { remove, isRemoving };
};

const exportRepairParts = params =>
  apiClient.post(
    "api/directory/repair-parts/export",
    {},
    {
      params,
    },
  );

export const useExportRepairParts = ({ onSuccess } = {}) => {
  const { search_term, filter_by } = useRepairPartsStore();

  const { mutate: startExport, isLoading: isExporting } = useMutation(
    () =>
      exportRepairParts({
        search_term,
        filter_by,
      }),
    {
      onSuccess: res => {
        onSuccess?.(res);
      },
    },
  );

  return { startExport, isExporting };
};

const importRepairParts = data =>
  apiClient.post("api/directory/repair-parts/import", data, {
    headers: { "Content-Type": "multipart/form-data" },
  });

export const useImportRepairParts = ({ onSuccess } = {}) => {
  const { mutate: upload, isLoading: isImporting } = useMutation(
    data => importRepairParts(data),
    {
      onSuccess: async res => {
        await queryClient.invalidateQueries(["repair-parts"]);
        onSuccess?.(res);
      },
    },
  );

  return { upload, isImporting };
};

const syncInventory = data =>
  apiClient.post("api/directory/repair-parts/import/sync", data, {
    headers: { "Content-Type": "multipart/form-data" },
  });

export const useSyncRepairPartsInventory = ({ onSuccess } = {}) => {
  const { mutate: upload, isLoading: isImporting } = useMutation(
    data => syncInventory(data),
    {
      onSuccess: async res => {
        await queryClient.invalidateQueries(["repair-parts"]);
        onSuccess?.(res);
      },
    },
  );

  return { upload, isImporting };
};
