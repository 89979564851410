import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Dropdown, App, Modal, Form } from "antd";
import {
  MoreOutlined,
  DeleteFilled,
  FormatPainterFilled,
  CaretRightOutlined,
  PlusOutlined,
  LoadingOutlined,
  TagsFilled,
} from "@ant-design/icons";
import {
  CategoriesInput,
  AccessoryInput,
  useAddModelAccessory,
  useAddModelColor,
  useInfAccessoriesStore,
  useRemoveModelAccessory,
  useRemoveModelColor,
} from "features/directory";
import { ColorShadeInput } from "features/devices";

const MODES = { color: "color", accessory: "accessory" };
const initialModalState = {
  isOpen: false,
  mode: null,
};

export const ModelOptionMenu = ({
  className = "",
  model,
  onRemove,
  showCategories = false,
}) => {
  const [form] = Form.useForm();
  const { modal } = App.useApp();
  const { deviceId } = useParams();
  const { setFilter, clearFilters } = useInfAccessoriesStore();

  // QUERIES

  const { addModelColor, isAddingModelColor } = useAddModelColor({
    deviceId,
    onSuccess: () => form.resetFields(),
  });
  const { remove, isRemoving, removingId } = useRemoveModelColor({ deviceId });

  const { addAccessory, isAddingAccessory } = useAddModelAccessory({
    deviceId,
    onSuccess: () => form.resetFields(),
  });
  const { removeAccessory, isRemovingAccessory, removingAccessoryId } =
    useRemoveModelAccessory({
      deviceId,
    });

  // MODAL

  const [modalState, setModalState] = useState(initialModalState);
  const openModal = mode => setModalState({ isOpen: true, mode: MODES[mode] });
  const closeModal = () => {
    setModalState({ isOpen: false, mode: null });
    form.resetFields();
  };

  // FORM

  const selectedColor = Form.useWatch("color", form);
  const selectedShade = Form.useWatch("color_shade_id", form);
  const selectedAccessory = Form.useWatch("accessory_id", form);
  const onSubmit = ({ color_shade_id, accessory_id }) => {
    modalState.mode === MODES.color
      ? addModelColor({ color_shade_id, option_id: model.id })
      : addAccessory({ accessory_id, option_id: model.id });
  };

  // MENU

  const items = useMemo(() => {
    return [
      {
        key: 0,
        label: "Colors",
        onClick: () => openModal("color"),
        icon: <FormatPainterFilled />,
      },
      {
        key: 2,
        label: "Accessories",
        onClick: () => openModal("accessory"),
        icon: <TagsFilled />,
        hidden: !showCategories,
      },
      {
        key: 1,
        label: "Delete",
        onClick: () => {
          modal.confirm({
            title: "Model Deleting",
            content: (
              <span>
                Do you really want to delete model
                <strong className="pl-1">{model.value}?</strong>
              </span>
            ),
            onOk: () => {
              onRemove();
              modal.info({
                title: "",
                content:
                  "To apply deletion, do not forget to press the Update button below.",
              });
            },
          });
        },
        icon: <DeleteFilled />,
        danger: true,
      },
    ].filter(item => !item.hidden);
  }, [showCategories]);

  // RENDER

  if (!model?.id) return null;

  const modalTitle =
    modalState.mode === MODES.color ? "Model Colors" : "Model Accessories";

  const disabledSubmit =
    (modalState.mode === MODES.color && !selectedShade) ||
    (modalState.mode === MODES.accessory && !selectedAccessory);

  return (
    <>
      <Dropdown menu={{ items }} trigger={["click"]}>
        <MoreOutlined className={className + " hover:text-gray-400"} />
      </Dropdown>

      <Modal
        title={modalTitle}
        open={modalState.isOpen}
        onCancel={closeModal}
        footer={null}
      >
        {modalState.mode === MODES.color &&
          model?.colors.map(item => (
            <ValuePairs
              values={[item.color?.color_name, item.name]}
              key={item.id}
              isProcessing={removingId === item.id}
              onDelete={() => {
                remove({ color_shade_id: item.id, option_id: model.id });
              }}
            />
          ))}

        {modalState.mode === MODES.accessory &&
          model?.accessories.map(item => (
            <ValuePairs
              values={[item.accessory_categories?.[0]?.name, item.name]}
              key={item.id}
              isProcessing={removingAccessoryId === item.id}
              onDelete={() => {
                removeAccessory({
                  accessory_id: item.id,
                  option_id: model.id,
                });
              }}
            />
          ))}

        <Form
          onFinish={onSubmit}
          form={form}
          className="flex items-center gap-2 my-4"
        >
          {modalState.mode === MODES.color && (
            <ColorShadeInput
              className="flex-1 items-center gap-2"
              itemClassName="m-0"
              hideLabels
              currentColor={selectedColor}
              disabled={isAddingModelColor || isRemoving}
              onChange={() => form.resetFields(["color_shade_id"])}
              onClear={() => {
                form.resetFields(["color", "color_shade_id"]);
              }}
            />
          )}

          {modalState.mode === MODES.accessory && (
            <div className="flex flex-1 items-center gap-2">
              <CategoriesInput
                rules={[]}
                name="category_id"
                placeholder="Select category"
                onChange={id => {
                  setFilter({ category_id: id });
                  form.resetFields(["accessory_id"]);
                }}
                onClear={() => {
                  form.resetFields();
                  clearFilters();
                }}
                className="m-0 flex-1"
                label={null}
                disabled={isAddingAccessory || isRemovingAccessory}
              />
              <CaretRightOutlined className="text-slate-400" />
              <AccessoryInput
                placeholder="Select accessory"
                name="accessory_id"
                className="m-0 flex-1"
                label={null}
                disabled={isAddingAccessory || isRemovingAccessory}
                onClear={() => form.resetFields(["accessory_id"])}
              />
            </div>
          )}

          <Button
            type="primary"
            htmlType="submit"
            className="shrink-0"
            disabled={disabledSubmit || isRemovingAccessory || isRemoving}
            icon={
              isAddingModelColor || isAddingAccessory ? (
                <LoadingOutlined />
              ) : (
                <PlusOutlined />
              )
            }
          />
        </Form>
      </Modal>
    </>
  );
};

const ValuePairs = ({
  values = ["first", "second"],
  onDelete,
  isProcessing,
}) => {
  const fakeInputClassName =
    "flex pl-3 h-[28px] my-1 items-center flex-1 border-1 border-gray-200 border-solid rounded bg-gray-100";

  return (
    <div className="flex items-center gap-2">
      <div className={fakeInputClassName}>{values[0]}</div>
      <CaretRightOutlined className="text-slate-400" />
      <div className={fakeInputClassName}>{values[1]}</div>

      <Button
        icon={
          isProcessing ? (
            <LoadingOutlined className="text-red-700" />
          ) : (
            <DeleteFilled className="text-red-700 opacity-20 hover:opacity-60" />
          )
        }
        type="ghost"
        onClick={onDelete}
      />
    </div>
  );
};
