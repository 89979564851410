import { useState } from "react";
import { Button, Form, Modal, Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useImportRepairParts, CategoriesInput } from "features/directory";
import { useMediaQuery } from "react-responsive";

export const ImportRepairParts = () => {
  const [form] = Form.useForm();
  const isTightly = useMediaQuery({
    query: "(max-width: 1000px)",
  });
  const { upload, isImporting } = useImportRepairParts({
    onSuccess: res => {
      message.success(res.message || "Repair parts imported successfully!");
      clearImport();
    },
  });

  const [file, setFile] = useState([]);
  const [isFormOpen, setIsFormOpen] = useState(false);

  const onSubmit = e => {
    const body = new FormData();
    body.append("repair_part_categories", e.repair_part_categories);
    body.append("file", file[0]);
    upload(body);
  };

  const clearImport = () => {
    setIsFormOpen(false);
    form.resetFields();
    setFile([]);
  };

  return (
    <>
      <Button
        onClick={() => setIsFormOpen(true)}
        icon={<UploadOutlined />}
        className="shrink-0"
      >
        {isTightly ? "" : "Import"}
      </Button>

      <Modal
        title="Import Repair Parts"
        open={isFormOpen}
        confirmLoading={isImporting}
        onCancel={() => {
          if (!isImporting) clearImport();
        }}
        onOk={form.submit}
        okText="Import"
        okButtonProps={{ disabled: !file.length }}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onSubmit}
          disabled={isImporting}
        >
          <Form.Item>
            <Upload
              name="file"
              withCredentials
              fileList={file}
              customRequest={({ file }) => {
                setFile([file]);
              }}
              onRemove={() => setFile([])}
            >
              {!file.length && <Button danger>Choose File</Button>}
            </Upload>
          </Form.Item>

          <CategoriesInput
            disabled={isImporting}
            name="repair_part_categories"
            mode="repairParts"
          />
        </Form>
      </Modal>
    </>
  );
};
